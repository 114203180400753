import React from "react";
import "../styles/pages/Home.css";

import Team from "../assets/images/team.png";
import Circles from "../assets/images/circles.svg";
import Affiche from "../assets/images/dinerdefamille.JPG";
import CoursAdultes from "../assets/images/IMG_3325.jpg";
import IconProg from "../assets/images/icon-prog.svg";
import IconSave from "../assets/images/icon-save.svg";
import LogoFB from "../assets/images/logo-fb.svg";
import LogoYT from "../assets/images/logo-yt.svg";
import BgText from "../assets/images/bg-textures.svg";

import HeadBanner from "../components/HeadBanner";
import Information from "../components/Information";
import Section from "../components/Section";
import H2 from "../components/Divers/H2";
import H3 from "../components/Divers/H3";
import Subtitle from "../components/Divers/Subtitle";
import Paragraph from "../components/Divers/Paragraph";
import Linker from "../components/Buttons/Linker";
import Banner from "../components/Banner";
import Review from "../components/Review";
import MiniLink from "../components/Divers/MiniLink";
import CardSocial from "../components/Divers/CardSocial";
import CTA from "../components/Buttons/CTA";
import CarouselFest from "../components/Divers/CarouselFest";

const Home = () => {
  return (
    <>
      <HeadBanner />
      <Information />
      {/* <div className="aya-container">
      <H2>A la une !</H2>
      <Subtitle></Subtitle>
        <CarouselFest />
      </div> */}
      <Section
        children={
          <>
            <H2>À propos de nous</H2>
            <Subtitle></Subtitle>
          </>
        }
        childrenTwo={
          <>
            <Paragraph>
              L'association a été fondée en 2015 par sa présidente, Ophélie
              Muret. Rapidement rejointe par le comédien professionnel Donat
              Guibert, elle offre des cours pour enfants, adolescents et adultes
              à Saint-Cyr-l'École (Yvelines).
            </Paragraph>
            <br></br>
            <Paragraph>
              Chaque année, l'association organise un festival de Théâtre
              Amateur, accueillant des troupes de différentes régions, et une
              troupe en particulier se distingue en parcourant la France pour
              partager la joie du rire à travers la comédie lors de divers
              festivals et événements.
            </Paragraph>
            <Linker to="/a-propos-de-nous">
              En savoir plus à propos de nous
            </Linker>
          </>
        }
        childrenThree={
          <>
            <img
              className="About-Image"
              src={Team}
              loading="lazy"
              alt="L'équipe La Divine Comédie"
            />
            <img className="Circles" src={Circles} loading="lazy" alt="" />
          </>
        }
      />
      <Section
        children={
          <>
            <H2>Vous partager notre passion</H2>
            <Subtitle>
              Découvrez notre passion à travers des cours animés par le
              talentueux Donat Guibert et des représentations scéniques à
              travers la France.
            </Subtitle>
          </>
        }
        childrenTwo={
          <>
            <img
              className="Comedy-Image"
              src={Affiche}
              loading="lazy"
              alt="Vu d'une scène de théatre"
            />
          </>
        }
        childrenThree={
          <>
            <H3>
              “Dîner de famille” notre pièce à la{" "}
              <span className="text-blue font-bold">une !</span>
            </H3>
            <Paragraph>
              Synopsis : A l'occasion de ses 30 ans, Alexandre souhaite demander
              à ses parents d'être les témoins de son mariage.<br></br>
              Son père, animateur de télé parisien, et sa mère, femme au foyer
              provinciale, sont fâchés depuis sa naissance.<br></br>
              Alexandre va utiliser de faux prétextes pour les réunir...
              <br></br>
              Le dîner de famille va-t-il totalement partir en vrille ?
            </Paragraph>
            <div className="section-minilink">
              <div className="content-minilink">
                <MiniLink
                  source={IconProg}
                  children={"La programmation"}
                  childrenTwo={"Trouvez nous peut être pas loin de chez vous."}
                />
                <Linker to="/nos-representations">Voir la programmation</Linker>
              </div>
              <div className="content-minilink">
                <MiniLink
                  source={IconSave}
                  children={"La réservation en ligne"}
                  childrenTwo={
                    // "Réservation bientôt disponible."
                    "Réservez via un service de billetterie en ligne."
                  }
                />
                <Linker
                  to="https://etincelleablis.fr/diner-de-famille-par-la-cie-la-divine-comedie/"
                  target={"_blank"}
                >
                  Réserver votre place
                </Linker>
              </div>
            </div>
          </>
        }
      />
      <Section
        children={""}
        childrenTwo={
          <>
            <H3>
              De l'école à la{" "}
              <span className="text-blue font-bold">scène:</span> Découvrez nos
              cours de théâtre.
            </H3>
            <Paragraph>
              Découvrez l'art du théâtre à travers nos cours à Saint-Cyr,
              ouverts à tous les niveaux et tous les âges. Encadrés par des
              professionnels passionnés, nos ateliers vous invitent à explorer
              le jeu d'acteur et à cultiver votre confiance en vous.
              <br></br>Rejoignez-nous pour un voyage captivant au cœur de
              l'expression théâtrale.
            </Paragraph>
            <Linker to="/nos-cours">Découvrir les cours de théatre</Linker>
          </>
        }
        childrenThree={
          <>
            <img
              className="cours-adultes"
              src={CoursAdultes}
              loading="lazy"
              alt="Cours adultes"
            />
          </>
        }
      />
      <Banner />
      <Review />
      <CardSocial
        additionalClasses="facebook"
        children={
          <div className="social-card-content">
            <img src={LogoFB} className="logo-fb" alt="Facebook" />
            <img src={BgText} className="bg-text" alt="" />
            <H3>
              <span className="text-white text-center font-bold">
                Suivez nous sur Facebook !
              </span>
            </H3>
            <div style={{ marginBottom: "30px" }}></div>
            <CTA
              to="https://www.facebook.com/La-Divine-Comédie-1789844364578891"
              target={"_blank"}
            >
              Voir notre page
            </CTA>
          </div>
        }
      />
      <CardSocial
        additionalClasses="youtube"
        children={
          <div className="social-card-content">
            <img src={LogoYT} className="logo-yt" alt="youutbe" />
            <img src={BgText} className="bg-text" alt="" />
            <H3>
              <span className="text-white text-center font-bold">
                Suivez nous sur Youtube !
              </span>
            </H3>
            <div style={{ marginBottom: "30px" }}></div>
            <CTA
              to="https://www.youtube.com/channel/UCnQAhjkJ9UqOZgG9iJPprfg"
              target={"_blank"}
            >
              Voir notre chaine
            </CTA>
          </div>
        }
      />
    </>
  );
};

export default Home;
